.shiping-barcode-scan-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -2rem;
  z-index: 1;

  .title-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem 0 3rem 0;
    text-align: center;
  }

  .logo-service-icon {
    top: 132px;
  }

  .title-text h1 {
    font-family: AlmoniTzar;
    font-size: 40px;
    font-weight: bold;
    padding: 5px 20px;
  }

  .barcode-reader-info {
    width: 606px;
    border-bottom: solid 2px #000;
    margin: 0 0 4rem 0;

    .locker-icon {
      margin: 0 2rem 0 -2rem;
    }
    .rectangle-barcode-icon {
      margin: 0 -4rem 0 0;
    }
    .rounded-barcode-icon {
      margin: 0 -6rem 0 0;
    }

    .info-text {
      display: inline-block;
      margin: -3rem 1rem -7rem -16rem;
      width: 158px;
      height: 400px;

      font-family: AlmoniTzar;
      font-size: 32px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.94;
      letter-spacing: 0.4px;
    }
  }
  .barcode-reader-info.exelot {
    position: relative;
    .exelot-locker-icon {
      margin: 0 -10.9rem 0 10.9rem;
    }
    .exelot-barcode {
      margin: -1rem 16rem 1rem -30rem;
    }
    .exelot-barcode-icon {
      margin: 4.5rem 20rem -4.5rem -25.5rem;
    }
    .info-text {
      position: absolute;
      display: inline-block;
      top: 210px;
      left: 100px;
      width: 158px;
      height: auto;
      font-family: Almoni;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.4px;
    }
  }
  .to-manual-barcode-input-button {
    height: 83px;
    width: auto;
    padding: 10px 16px;
    margin: -2rem 0 -5rem 0;
    border: #fc5726 solid 2px;
    background: #fff;
    direction: ltr;
  }

  .to-manual-barcode-input-button.rtl {
    direction: rtl;
  }

  .to-manual-barcode-input-button .manual-input-icon {
    margin: 0 0 0 90px;
  }

  .to-manual-barcode-input-button.rtl .manual-input-icon {
    margin: 0 90px 0 0;
  }
}
.logo-service-icon {
  position: fixed;
  top: 143px;
  right: 63px;
  padding: 9px 7px;
  z-index: 1;
}
.logo-service-icon.dhl {
  background-color: #ffcc01;
}

.retry-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.retry-text {
  margin-top: 4rem;
  font-size: 32px;
  font-weight: 600;
  font-family: AlmoniTzar;
}
