.lim-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
}

.lim-label-extra-class {
  font-family: Almoni;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  /* padding: 0 30px; */
}

.lim-close-btn {
  padding: 0 10px;
  font-size: 24px;
}

.lim-content-extra-class {
  font-family: Almoni;
  font-size: 20px;
  max-width: 850px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 0 10px;
}

.lim-content-extra-class::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.lim-content-extra-class::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.lim-content-extra-class::-webkit-scrollbar {
  width: 13px;
}
