.rps-wrapper {
  min-height: 100vh;
  display: flex;
}

.rps-frame-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.margin-top-25 {
  margin-top: -25rem;
}

.rps-frame-container {
  display: flex;
  justify-content: center;
}

.rps-body {
  flex: 10;
}

.rps-footer {
  flex: 1;
}

.rps-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.rps-details-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rps-details-single-btn {
  font-size: 30px;
}

.rps-frame-inner-wrapper {
  padding: 5px 75px 7px 60px;
  display: flex;
  flex-direction: column;
}
.rps-title{
  font-family: AlmoniTzar;
  font-size: 44px;
  font-weight: 600;
}
.rps-sub-title{
  font-family: AlmoniTzar;
  font-size: 24px;
  font-weight: 300;
}