.acm-details-btns {
  width: 40vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}
.acm-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 38px;
}
.acm-discount-text {
  align-items: center;
  font-size: 18px;
  text-align: center;
  padding: 6px;
  font-weight: bold;
}
.coupon-modal-move-up {
  margin-top: -22vh;
}
