.nop-frame-container {
  display: flex;
  justify-content: center;
  /* background: red; */
}

.nop-frame-inner-wrapper {
  display: flex;
}

.nop-frame-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.nop-frame-single-btn {
  font-size: 25px;
}
