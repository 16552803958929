.ddocs-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0 auto;
  width: 90vw;
  height: 500px;
  margin-bottom: -2rem;
}

.ddocs-text-instructions-wrapper {
  font-size: 20px;
  font-family: Almoni;
  color: white;
  margin: 10px;
  padding: 20px 0;
}

.ddocs-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 20px 150px;
  margin-bottom: 5px;
}
.ddocs-inner-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.ddocs-inner-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.ddocs-inner-wrapper::-webkit-scrollbar {
  width: 13px;
}
.ddocs-inner-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.ddocs-inner-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}
.unlock-screen-title{
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
  margin: -5rem auto 0 auto;
}
.unlock-screen-subtitle{
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 22px;
  margin: -2rem auto -2rem auto;
}
.open-all-lockers-btn{
  z-index: 1;
  margin: 1rem auto -6rem auto;
  width: 300px;
}