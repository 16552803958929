.itls-itm-wrap {
  display: flex;
  border-radius: 8px;
  color: #515151;
  font-family: Almoni;
  font-size: 16px;
  font-weight: 600;
  margin: 3px 0;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px white;
  min-width: 60px;
  margin-right: auto;
}
.item-title {
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px white;
  padding: 0 10px;
  line-height: 1;
}

.trash {
  opacity: 0.5;
  padding: 5px 10px;
  height: 35px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px white;
}
.trash img {
  height: 25px;
}

.itls-suite-case {
  max-width: 34px;
  margin: 0 10px;
}

.itls-itm-row-flex2 {
  flex: 2;
}
