.tb-wrapper {
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  align-items: center;
  font-family: Almoni;
  font-size: 18px;
  font-weight: 500;
  margin: 1rem 1rem 0 2rem;
  color: black;

  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  align-items: center;
  font-family: Almoni;
  font-size: 18px;
  font-weight: 500;
  margin: 1rem 1rem 0 2rem;
  color: black;
}

.rtl .tb-wrapper .amount-info {
  text-align: right;
}
.ltr .tb-wrapper .amount-info {
  text-align: left;
}

.rtl .tb-wrapper .discount-info {
  text-align: left;
}
.ltr .tb-wrapper .discount-info {
  text-align: right;
}

.rtl .tb-wrapper .amount-info {
  text-align: right;
  width: 155px;
}
.ltr .tb-wrapper .amount-info {
  text-align: left;
  width: 155px;
}

.rtl .tb-wrapper .discount-info {
  text-align: left;
}
.ltr .tb-wrapper .discount-info {
  text-align: right;
}
