.warndoor-close-btn {
  /* width: 10%; */
  margin-left: 1rem;
  margin-right: -2rem;
  justify-self: flex-start;
}

.warndoor-margin-bottom {
  margin-bottom: 10px;
}
.warndoor-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 32px;
  margin-left: -1rem;
}
.warndoor-sub-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 20px;
  margin-left: -1rem;
}
.warndoor-titles-and-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.warndoor-titles {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.warndoor-cells-wrapper {
  display: flex;
  align-items: center;
  height: 550px;
  width: 95%;
  overflow: auto;
}
.warndoor-cells-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}

.warndoor-cells-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.warndoor-cells-wrapper::-webkit-scrollbar {
  width: 13px;
} 

.warndoor-cells-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.warndoor-cells-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}