.service-delivery-menu {
  .delivery-service-menu-button {
    .orange-part {
      height: 117px;
    }
    .big-white-part {
      height: 163px;
    }
    img.locker-delivery {
      height: 157px;
      margin-bottom: 36px;
    }
  }
}

.ls-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.main-title {
  align-self: center;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #000;
  margin-top: -5rem;
  direction: rtl;
}
.testing-version {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  font-size: 32;
  color: #000;
  font-weight: 600;
}

.dhl-package-delivery-info {
  width: 224px;
  margin: -72px 514px;
  font-family: Almoni;
  font-size: 18px;
  letter-spacing: normal;
  text-align: center;
}
