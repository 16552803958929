.done-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -2rem;
}

.done-login-container .enter-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0.3rem;
  text-align: center;
}
.done-login-container .enter-text h1 {
  font-family: AlmoniTzar;
  font-size: 40px;
  font-weight: bold;
}

.done-login-container .enter-text h2 {
  font-family: Almoni;
  font-size: 22px;
  font-weight: bold;
}

.done-login-container .barcode-reader-info {
  width: 606px;
  border-bottom: solid 2px #000;
  margin: 0 0 6rem 0;
}

.done-login-container .barcode-reader-info .orange-arrow-icon {
  margin: 0 0 -32rem -2.5rem;
}
.done-login-container .barcode-reader-info .orange-locker-done-login-icon {
  margin: 0 -2rem -2.1rem -2rem;
}
.done-login-container .barcode-reader-info .man-cell-phone-icon {
  margin: 0 0 -10rem 0.5rem;
}

.done-login-container .to-user-login-button {
  height: 83px;
  width: auto;
  padding: 10px 16px;
  margin: -4rem 0 -1rem 32rem;
}
.done-login-container .to-user-login-button .login-with-pass-shape {
  margin: 0 50px 0 0;
}
