.rental-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  width: 100vw;
}
.about-rental-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 3rem;
}
.text-and-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10rem;
}
.img-locker-wrapper {
  margin: 0 9rem 2rem 50px;
}
.img-locker-wrapper img {
  width: 215px;
  height: 230px;
}
.about-rental-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 8rem;
}
.about-rental-string {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  margin-bottom: 1rem;
}
.thanks {
  tab-size: 4;
}
.rent-info-dot {
  margin: 0 1rem;
}
.about-rental-footer {
  margin-top: -3rem;
}
.rent-locker-info-button{
  margin: auto;
  margin-top: -7rem;
}