.dms-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 2rem;
}
.dms-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
  margin: 3rem auto 0 auto;
}
.dms-subtitle {
  margin: 1rem auto;
}