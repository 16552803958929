.blsel-wrapper {
  display: flex;
  flex: 1;
  font-size: 30px;
  font-family: Almoni;
  font-weight: 500;
  margin: 5px;
  flex-direction: column;
  user-select: none;
}

.blsel-displayed-itm {
  width: 150px;
  display: flex;
  background: rgba(194, 194, 194, 0.23);
  align-items: center;
  justify-content: space-between;
  /* padding: 0 15px; */
  border-radius: 8px;
  /* min-height: 40px; */
}

.blsel-arrow-down {
  width: 10px;
  height: 10px;
  background-color: rgba(194, 194, 194, 0);
  border: 5px solid #fc5726;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
}
.blsel-select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(194, 194, 194, 0);
  width: 40px;
  height: 50px;
  border: none;
  border-right: solid 1px white;
  border-radius: 8px 0 0 8px;
}

.blsel-itms-list-wrapper {
  position: relative;
  flex: 1;
}
.hide-list {
  display: none;
}

.blsel-itms-list {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow: auto;
  width: 100%;
  margin-top: 0.1rem;
  border-radius: 8px;
  border: 1px solid rgba(194, 194, 194, 0.23);
}

.blsel-itm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.blsel-picker {
  background: rgba(194, 194, 194, 0.23);
}

.blsel-itms-list::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.blsel-itms-list::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.blsel-itms-list::-webkit-scrollbar {
  width: 13px;
}
