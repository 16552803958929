.cpbm-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
  max-width: 800px;
}
.timer-modal-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 42px;
}
.timer-modal-sub-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
}
.loader-mb {
  margin-bottom: 5rem;
}
.timer-modal-btn {
  margin: 0 auto 1rem auto;
}
