.fixed-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-align: center;
  direction: ltr;
}
.logo-wrapper {
  padding: 20px 0 20px 44px;
}

.back-button {
  margin-left: auto;
  margin-right: 3.5rem;
  margin-bottom: 1rem;
}
.otp-mobile-footer{
  margin-top: -16rem;
}