.printed-labelless {
  width: 70vw;
  height: 70vh;
  top: 15vh;
  bottom: 15vh;
  right: 15vw;
  left: 15vw;
  .printed-labelless-modal {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 50px 90px;
    flex-direction: column;
    justify-content: center;
    .title-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      font-family: AlmoniTzar;
      font-weight: 300;
      font-size: 38px;
      color: black;
      margin: 20px 0;
    }
    .services-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 500px;
      font-family: AlmoniTzar;
      .delivery-service-menu-button {
        .orange-part {
          height: 117px;
        }
        .big-white-part {
          height: 163px;
          .card-text {
            font-size: 1.8rem;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
