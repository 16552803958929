.itls-wrapper {
  min-width: 290px;
  height: 345px;
  margin: 0 5px;
  overflow-y: auto;
  padding: 0 10px;
}
.itls-wrapper::-webkit-scrollbar {
  position: absolute;
  margin-right: 50px;
  width: 13px;
}
.itls-wrapper::-webkit-scrollbar-thumb {
  background-color: #fc5726;
  border-radius: 11px;
}
.itls-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}
.itls-wrapper::-webkit-scrollbar {
  width: 13px;
}

.orange {
  background-color: rgba(252, 87, 38, 0.1);
}
.grey {
  background-color: rgba(194, 194, 194, 0.23);
}

.new-order-screen .list-and-footer .order-number {
  width: 337px;
  margin: -30px auto 10px auto;
}
