.comm-txt-area {
  background: white;
  outline: none;
  border: solid 2px white;
  border-radius: 10px;
  font-family: Almoni;
  resize: none;
  overflow: auto;
  padding: 4px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  height: 100%;
}

.comm-txt-area-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 445px;
  height: 100px;
  padding: 5px;
  border: 1px solid #fc5726;
  border-radius: 8px;
  margin: 0 auto 0.3rem auto;
  /* margin-bottom: 0.3rem; */
}
