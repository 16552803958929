.blbtn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: AlmoniTzar;
  font-size: 30px;
  font-weight: 400;
  user-select: none;
  margin: 5px;
  background-color: #fc5726;
  border: none;
  border-radius: 10px;
  min-width: 230px;
  height: 40px;
  line-height: 0.6;
  padding: 0.4rem 0.6rem;
}

.blbtn-wrapper:active {
  font-size: 28px;
  height: 34px;
}

