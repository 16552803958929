.select-station-title {
  display: flex;
  font-size: 32px;
  font-weight: 600;
  margin: -3rem auto -2rem auto;
}

.select-station-wrapper .select-wrapper {
  width: 400px;
  margin: 40px 40px 0 40px;
  display: inline-block;
}
.clear-button-wrapper {
  min-width: 108px;
  margin: 40px 40px 0 40px;
  display: inline-block;
}
.select-station-wrapper{
  max-height: 570px;
}
.select-station-wrapper .select-wrapper .search-input {
  font-size: 24px;
  font-weight: bold;
  color: #fc5726;
  line-height: 18px;
  border-radius: 7px;
  border: 1px solid #fc5726;
  white-space: nowrap;
  text-align: inherit;
  min-width: 10px;
  height: 40px;
  padding: 12px 10px;
}

.select-station-wrapper .select-wrapper .search-input::placeholder {
  color: #fc5726;
  font-size: 25px;
  text-align: inherit;
}

.select-station-wrapper .station-list-wrapper {
  height: 425px;
  width: 971px;
  display: flex;
  margin: 0 auto;
}

.select-station-wrapper .continue-button-wrapper {
  display: block;
  width: 240px;
  margin: 45px auto -45px;
}

.select-station-wrapper .continue-button-wrapper.active {
  position: fixed;
  bottom: 356px;
  left: 377px;
  right: 367px;
  z-index: 5;
}

.select-station-wrapper .map-button-container {
  position: relative;
  z-index: 2;
  direction: rtl;
}
.big-react-modal .map-button-container {
  position: relative;
  z-index: auto;
  width: 100%;
  height: 100%;
  direction: rtl;
}

.locker-to-locker-title {
  display: flex;
  font-size: 32px;
  font-weight: 600;
  margin: -3rem auto -3rem auto;
}

.base-select__control {
  border-radius: 6px;
}

.base-select__indicator-separator,
.base-select__control,
.base-select__control:hover {
  border: 1px solid #fc5726 !important;
  color: #fc5726 !important;
  box-shadow: none !important;
}

.base-select__placeholder {
  font-size: 26px;
  font-weight: bold;
  color: #fc5726 !important;
}
.base-select__value-container {
}
.base-select__multi-value {
  background: rgba(41, 167, 223, 0.2);
  border-radius: 21px;
  padding: 2px 4px;
}
.base-select__multi-value__label {
  opacity: 1;
}

.base-select__menu {
  max-height: 300px !important;
}

.base-select__menu-list::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.base-select__menu-list::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}

.base-select__menu-list::-webkit-scrollbar {
  width: 13px;
}

.base-select__menu-list {
  max-height: 300px !important;
}

.base-select__option {
  color: #fc5726 !important;
  font-size: 24px;
  line-height: 18px;
  border-radius: 6px;
  white-space: nowrap;
}
.base-select__option:hover {
  background: rgba(41, 167, 223, 0.2);
}

.base-select__indicator:hover,
.base-select__indicator {
  color: #fc5726 !important;
  display: inline-block;
}

.base-select__single-value,
.base-select__menu,
.base-select__input,
.base-select__input-container {
  line-height: 18px !important;
  color: #fc5726 !important;
  font-size: 24px;
  font-weight: bold;
}
.select-station-wrapper .maximize-button {
  position: absolute;
  top: 2.4rem;
  margin: 0 1.1rem;
  text-align: start;
  width: 100%;
  border: none;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  z-index: 1001;
}
.big-react-modal .minimize-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  z-index: 1001;
}
.select-station-wrapper .minimize-button {
  position: absolute;
  top: 3.5rem;
  right: 4rem;
  /* left: 28rem; */
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  z-index: 1001;
}
.resize-btn-img {
  width: 100%;
}
