.change-password-title {
  text-align: center;
  font-family: Almoni;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 2rem;
}
.iframe_policy{
  width: 800px;
  height: 550px;
  margin: 1rem 0;
}