.sus1s-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sus1s-frame-wrapper {
  display: flex;
  flex-direction: column;
}

.sus1s-frame-container {
  display: flex;
  justify-content: center;
}

.sus1s-body {
  flex: 10;
}
.sus1s-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 50px;
  position: absolute;
  right: 420px;
  top: 10px;
}

.sus1s-footer {
  flex: 1;
}
.sus1s-frame-inner-wrapper {
  display: flex;
  justify-content: center;
  //margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.sus1s-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.sus1s-details-btns1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 735px;
}

.sus1s-details-single-btn {
  font-size: 30px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.btn-margin {
  //margin: 15px auto 0 auto;
  margin: 0 auto 0 auto;
}
