.sender-title {
  display: flex;
  font-size: 40px;
  font-weight: 600;
  margin: -16px auto 23px auto;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: 0.5px;
  text-align: center;
}
.sender-wrapper {
  .logo-service-icon {
    top: 94px;
  }
  .input-wrapper input {
    height: 60px;
    font-size: 50px;
  }
  .input-wrapper-underline {
    display: block;
    height: 1px;
    width: 270px;
    margin: 0 auto;
    border-bottom: solid 1px #fc5726;
  }
  .input-line-1 {
    display: flex;
    justify-content: center;
    margin-bottom: 1.8rem;
  }

  .input-line-2 {
    display: flex;
    justify-content: center;
    margin-bottom: 1.2rem;
  }
  .tapcb-wrapper {
    margin: 0.5rem 1rem 0.5rem;
  }
}

.react-modal-content.summary {
  width: 70%;
  height: 70%;
  top: 15%;
  bottom: 15%;
  left: 20%;
  right: 20%;
}
.summary-wrapper {
  font-family: AlmoniTzar;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding: 60px;
}

.summary-wrapper .header-button {
  margin-right: 0;
  margin-left: 0;
}

.summary-title {
  font-family: AlmoniTzar;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.05;
  letter-spacing: 0.5px;
  text-align: center;
}
.summary-sub-title {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin: 15px 0 0 0;
}
.summary-label {
  width: 200px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: start;
  margin: 50px 0 0 0;
  display: inline-block;
}
.summary-text {
  width: 230px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: start;
  margin: 25px 0 0 0;
  display: inline-block;
}

.summary-wrapper .white-btn-wrapper {
  width: 200px;
  margin: 34px auto 24px auto;
  font-size: 30px;
}

.logo-service-icon {
  position: fixed;
  top: 143px;
  right: 63px;
  padding: 9px 7px;
  z-index: 1;
}
.logo-service-icon.dhl {
  background-color: #ffcc01;
}
