.addressee_title {
  display: flex;
  font-size: 40px;
  font-weight: 600;
  margin: -30px auto 23px auto;
  color: #000;
}

.addressee-wrapper .input-wrapper input {
  height: 60px;
  font-size: 50px;
  caret-color: #fc5726;
}
.addressee-wrapper .input-wrapper-underline {
  display: block;
  height: 1px;
  width: 270px;
  margin: 0 auto;
  border-bottom: solid 1px #fc5726;
}
.react-modal-content.summary {
  width: 70%;
  height: 70%;
  top: 15%;
  bottom: 15%;
  left: 20%;
  right: 20%;
}
.summary-wrapper {
  font-family: AlmoniTzar;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding: 60px;
}

.summary-wrapper .header-button {
  margin-right: 0;
  margin-left: 0;
}

.summary-title {
  font-family: AlmoniTzar;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.05;
  letter-spacing: 0.5px;
  text-align: center;
}
.summary-sub-title {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin: 15px 0 0 0;
}
.summary-label {
  width: 200px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: start;
  margin: 50px 0 0 0;
  display: inline-block;
}
.summary-text {
  width: 230px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: start;
  margin: 25px 0 0 0;
  display: inline-block;
}

.summary-wrapper .white-btn-wrapper {
  width: 200px;
  margin: 34px auto 24px auto;
  font-size: 30px;
}
