.locker-to-locker-info .continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto -2rem auto;
  z-index: 10;
}
.locker-to-locker-info .info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0 auto;
}

.locker-to-locker-info .title {
  display: flex;
  font-size: 40px;
  font-weight: 600;
  margin: 10px auto 0 auto;
}

.locker-to-locker-info .info-wrapper .info-icon {
  display: inline-flex;
  align-self: start;
}

.locker-to-locker-info .info-wrapper .info-text {
  padding: 45px 20px 0 20px;
}

.locker-to-locker-info .info-wrapper .item {
  display: block;
  position: relative;
  margin: 0 9px 30px 0;
  font-size: 24px;
  letter-spacing: 0.3px;
}
.locker-to-locker-info .info-wrapper .item {
  margin: 0 0 30px 9px;
}

.locker-to-locker-info .info-wrapper .info-text .item .text {
  width: 554px;
  padding: 0 19px 0 0;
}

.locker-to-locker-info.ltr .info-wrapper .info-text .item .text {
  padding: 0 0 0 19px;
}

.locker-to-locker-info .info-wrapper .info-text .item .oval {
  position: absolute;
  top: 5px;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #f1612b;
  border-radius: 5px;
}

.locker-to-locker-info.ltr .info-wrapper .info-text .item .oval {
  right: auto;
  left: 0;
}
