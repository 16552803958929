.errinm-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(70, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  /* z-index: 1; */
}

.errinm-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
  max-width: 800px;
}

.errinm-label-extra-class {
  text-align: center;
  font-family: AlmoniTzar;
  font-size: 35px;
  font-weight: 800;
  padding: 10px 0;
}

.errinm-label-extra-class2 {
  font-size: 20px;
  font-family: Almoni;
  padding: 1rem 0 2rem 0;
}
.wheelchair-wrapper{
margin-bottom: 1rem;
}