.white-btn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: AlmoniTzar;
  font-size: 25px;
  font-weight: 600;
  user-select: none;
  margin: 5px;
  background-color: #fff;
  border: solid 1px #fc5726;
  border-radius: 10px;
  min-width: 125px;
  width: 230px;
  height: 40px;
  line-height: 0.6;
  padding: 0.4rem 0;
}

/* .blbtn-wrapper:active {
  font-size: 28px;
} */
/* .blbtn-wrapper:hover {
  background-color: #fc5726;
} */
