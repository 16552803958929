.rps-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rps-frame-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rps-body {
  flex: 10;
}

.rps-footer {
  flex: 1;
}
.restore-code-titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.restore-code-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
}
.restore-code-sub-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 20px;
}

.rps-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.rps-details-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rps-details-single-btn {
  font-size: 24px;
}

.rps-frame-inner-wrapper {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}
