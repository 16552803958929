.sdu-move-up {
  margin-top: -170px;
}
.service-modal-header {
  padding: 0;
  height: 25px;
  margin-bottom: 1rem;
}
.service-modal-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 38px;
  color: black;
}
.service-and-quantity {
  height: 180px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
}
.quantity-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.serv-details-container {
  display: flex;
  flex-direction: column;
}
.service-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-service-icon {
  height: 170px;
  width: 170px;
  font-size: 16px;
}
.modal-service-icon img {
  height: 110px;
}

.quantity-title {
  margin-right: 3rem;
  font-family: AlmoniTzar;
  font-weight: 600;
  color: #fc5726;
  font-size: 32px;
}

.serv-details-row {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.ser-det-lbl-extrclass {
  justify-content: space-between;
  margin: 0 30px;
}

.serv-details-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.serv-details-single-btn {
  padding: 5px 15px;
  margin: 0 20px;
  font-size: 25px;
}
