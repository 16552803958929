.lockcell-wrapper {
  direction: ltr;
  display: flex;
  justify-content: flex-start;
  user-select: none;
  padding: 0.1rem 2rem;
  width: fit-content;
  max-height: 100%;
  /* overflow: auto; */
  margin: auto;
}
.centered {
  justify-content: center;
}

.lockcell-col-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
