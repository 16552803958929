.prls-tabl-bdy-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-family: Almoni;
  font-weight: 600;
  font-size: 1rem;
  border: solid white 1px;
  border-radius: 8px;
}

.prls-tabl-bdy-prd-name {
  flex: 8;
  padding-left: 1rem;
  padding-right: 1rem;
}

.prls-tabl-bdy-price {
  flex: 1;
  text-align: center;
  padding: 0 10px;
}
.grey {
  background-color: rgba(194, 194, 194, 0.23);
}
.orange {
  background-color: rgba(252, 87, 38, 0.1);
}
