.amount-num-picker-wrapper {
  user-select: none;
  width: 50px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.amo-num-pick-itm {
  flex: 1;
  display: flex;
  background: rgba(194, 194, 194, 0.23);
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 25px;
  border: 1px solid white;
  width: 50px;
  align-items: center;
  justify-content: center;
}

.amo-num-pick-pls {
  border-radius: 8px 8px 0 0;
  color: #fc5726;
}

.amo-num-pick-mns {
  border-radius: 0 0 8px 8px;
  color: #fc5726;
}

.amo-num-pick-btn {
  font-weight: 800;
}

.amo-num-pick-btn:active {
  background: #fc5726;
  color: black;
}
