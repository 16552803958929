.scroll-box-wrapper {
  color: #000;
  font-family: Almoni;
  font-size: 25px;
  font-weight: 500;
}
.scroll-box-wrapper .title {
  display: flex;
  font-size: 32px;
  font-weight: 500;
}

.scroll-box-wrapper .title {
  display: flex;
  padding: 20px 0 0 0;
}

.scroll-box-wrapper .title .title-text {
  padding: 0 10px;
  letter-spacing: 0.4px;
  font-family: AlmoniTzar;
  font-weight: bold;
}

.scroll-box-wrapper .scroll-box-content {
  width: 800px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 1rem;
}

.scroll-box-wrapper .scroll-box-content::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.4);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.scroll-box-wrapper .scroll-box-content::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.scroll-box-wrapper .scroll-box-content::-webkit-scrollbar {
  width: 14px;
}

.scroll-box-wrapper .scroll-box-content .request-order-item {
  background-color: rgba(252, 87, 38, 0.1);
}
.scroll-box-wrapper .scroll-box-content .request-order-item:nth-child(2n) {
  background-color: rgba(194, 194, 194, 0.23);
}
