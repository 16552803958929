.login-with-otp {
  .content-wrapper {
    height: 470px;
    margin-top: -18rem;

    .input-wrapper {
      padding-bottom: 1.2rem;
    }
    .otp-wrapper {
      margin: 0 auto;
    }
    .title-otp-input-mobile {
      font-size: 44px;
      font-weight: 600;
    }
  }
}
