.barcode-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.barcode-reader-wrapper {
  /* min-width: 620px;  */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.barcode-reader-wrapper .c-container {
  margin: 20px 0 75px 0;
}
