.select-station-list-wrapper {
  width: 482px;
  height: 415px;
  overflow-y: auto;
  margin-top: 1rem;
  margin: 30px 10px 74px 13px;
  background-color: #fff;
  border-radius: 10px;
}
.select-station-list-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.select-station-list-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.select-station-list-wrapper::-webkit-scrollbar {
  width: 13px;
}
