* {
  margin: 0;
  padding: 0;
  line-height: 1;
  /* direction: rtl; */
  /**UNCOMMENT TO HIDE CURSOR! ::*/
  /* cursor: none; */
}
p {
  margin: 0;
}
html {
  max-width: 1024px;
  height: 768px;
  max-height: 768px;
  margin: 0 auto;
  /* PREVENTING SWIPE BACK SCROLL */
  /* overscroll-behavior: none; */
}

body {
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  /* PREVENTING SWIPE BACK SCROLL */
  overscroll-behavior: none;
}

.dirrtl {
  direction: rtl;
}

.dirltr {
  direction: ltr;
}

.abc {
  border: dashed darkblue 3px;
}

.abd {
  border: dashed pink 3px;
}

@font-face {
  font-family: Almoni;
  src: url(assets/fonts/Almoni/almoni-regular-aaa.woff),
    url(assets/fonts/Almoni/almoni-regular-aaa.woff2);
}
@font-face {
  font-family: AlmoniTzar;
  src: url(assets/fonts/Almoni/almoni-tzar-regular-aaa.woff),
    url(assets/fonts/Almoni/almoni-tzar-bold-aaa.eot),
    url(assets/fonts/Almoni/almoni-tzar-bold-aaa.woff),
    url(assets/fonts/Almoni/almoni-tzar-bold-aaa.woff2),
    url(assets/fonts/Almoni/almoni-tzar-demibold-aaa.eot),
    url(assets/fonts/Almoni/almoni-tzar-demibold-aaa.woff),
    url(assets/fonts/Almoni/almoni-tzar-demibold-aaa.woff2),
    url(assets/fonts/Almoni/almoni-tzar-regular-aaa.eot),
    url(assets/fonts/Almoni/almoni-tzar-regular-aaa.woff2);
}
@font-face {
  font-family: "PlacardNext-CondBold";
  src: url("assets/fonts/font.woff2") format("woff2"),
    url("assets/fonts/font.woff") format("woff");
}
