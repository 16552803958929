.otp-input {
  width: 3rem;
  height: 3rem;
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  border-bottom: 3px #fc5726 solid;
  border-radius: 5px;
  margin-left: 1rem;
  padding-bottom: 10px;
  &:focus {
    outline: none;
  }
}
.otp-input-field {
  display: flex;
  margin-top: 5rem;
  margin-right: 1rem;
  justify-content: center;
}
.otp-input-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;
}
