.station-item-wrapper {
  display: flex;
}

.station-item-wrapper .station-item-text-wrapper {
  display: block;
  width: 370px;
  margin: 0 16px 14px 16px;
  border-radius: 8px;
  background-color: rgba(194, 194, 194, 0.23);
  padding: 16px 20px;
  font-family: Almoni;
  color: #000;
  text-align: start;
}

.station-item-wrapper.active .station-item-text-wrapper {
  background-color: #fc5726;
  color: #fff;
}
.station-item-wrapper .icon {
  display: flex;
  align-self: flex-start;
  width: 28px;
  height: 40px;
}
.station-item-wrapper .city-name {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 0.56px;
}

.station-item-wrapper .station-name {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  padding: 0 6px;
}

.station-item-wrapper .station-address {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  min-width: 200px;
}
