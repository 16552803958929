.service-menu-button {
  cursor: pointer;
  position: relative;
  width: 195px;
  margin: 0 0.5rem;
}
.orange-part {
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fc5726;
  border-radius: 8px 8px 0 0;
}
.white-part {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: #fc5726 solid 2px;
  border-radius: 0 0 8px 8px;
}
.big-white-part {
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: #fc5726 solid 2px;
  border-radius: 0 0 8px 8px;
}
.middle-img {
  //margin: -80px auto 30px auto;
  position: absolute;
  top: -38px;
}
.delivery-service-menu-button.oneProject-wrapper,
.delivery-service-menu-button.dhl-wrapper,
.delivery-service-menu-button.exelot-wrapper,
.delivery-service-menu-button.hfd-wrapper,
.delivery-service-menu-button.returnsExternal-wrapper,
.delivery-service-menu-button.locker-delivery-wrapper {
  cursor: pointer;
  position: relative;
  margin: 0 0.5rem;
  width: 260px;
  img {
    max-width: 250px;
  }
}

.thin-button {
  .delivery-service-menu-button.oneProject-wrapper,
  .delivery-service-menu-button.dhl-wrapper,
  .delivery-service-menu-button.exelot-wrapper,
  .delivery-service-menu-button.hfd-wrapper,
  .delivery-service-menu-button.returnsExternal-wrapper,
  .delivery-service-menu-button.locker-delivery-wrapper {
    margin: 0 0.6rem;
    width: 200px;
    img {
      max-width: 190px;
    }
  }
}

.delivery-service-menu-button.dhl-wrapper {
  .orange-part {
    background-color: #ffcc01;
  }
  .white-part {
    border: #ffcc01 solid 2px;
  }
  .big-white-part {
    border: #ffcc01 solid 2px;
  }
}

.delivery-service-menu-button.exelot-wrapper {
  .orange-part {
    background-color: #00529a;
  }
  .white-part {
    border: #00529a solid 2px;
  }
  .big-white-part {
    border: #00529a solid 2px;
  }
}

.delivery-service-menu-button.hfd-wrapper {
  .orange-part {
    background-color: #ed1c28;
  }
  .white-part {
    border: #ed1c28 solid 2px;
  }
  .big-white-part {
    border: #ed1c28 solid 2px;
  }
}

.delivery-service-menu-button.returnsExternal-wrapper {
  .orange-part {
    background-color: #00529a;
  }
  .white-part {
    border: #00529a solid 2px;
  }
  .big-white-part {
    border: #00529a solid 2px;
  }
}

.delivery-service-menu-button.oneProject-wrapper {
  .orange-part {
    background-color: #000;
    border: #000 solid 2px;
    border-bottom: 0;
  }
  .big-white-part {
    border: #000 solid 2px;
  }
}

.delivery-service-menu-button .package-delivery-info {
  font-family: Almoni;
  font-size: 18px;
  letter-spacing: normal;
  text-align: center;
}

.card-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.9rem;
  padding: 0 8px;
}
.laundry {
  width: 180px;
  margin: 0 0 55px 40px;
}
.delivery {
  width: 155px;
  margin: 0 10px 55px 0px;
}
.locker-delivery {
  width: 160px;
  margin-bottom: 65px;
}
.locker-rent {
  width: 155px;
}
