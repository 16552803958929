.done-login-btn-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1.5rem;
  width: 500px;
}
.done-login-btn-wrapper .orange-part {
  position: relative;
  width: 291px;
  height: 253px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fc5726;
  border-radius: 8px 8px 0 0;
}
.qr-code-shape {
  position: absolute;
  top: 20px;
  left: 20px;
}
.done-login-btn-wrapper .white-part {
  width: 291px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: #fc5726 solid 2px;
  border-radius: 0 0 8px 8px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.done-login-btn-wrapper .enter-by-app-title {
  font-size: 40px;
  text-align: center;
  margin: 0 0 50px 0;
  font-family: AlmoniTzar;
  font-weight: bold;
}

.done-login-btn {
  width: 410px;
  height: 65px;
  padding-top: 0.35rem;
  font-weight: 600;
  font-size: 2.1rem;
  letter-spacing: 1.3px;
}
.done-login-btn:active {
  width: 405px;
  height: 60px;
}
