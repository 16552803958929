.order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: AlmoniTzar;
  font-size: 32px;
  font-weight: 600;
  color: #fc5726;
}

.name-header {
  margin-left: 7.3rem;
}
.row-background {
  height: 48px;
}
.modal-details-list {
  width: 400px;
  height: 370px !important;
}
