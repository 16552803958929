.rodl-wrapper {
  /* min-width: 601px; */
  width: 800px;
  max-height: 160px;
  overflow-y: auto;
  margin-top: 1rem;
}

.rodl-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.rodl-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.rodl-wrapper::-webkit-scrollbar {
  width: 13px;
}
.ready-orders-list {
  margin-bottom: -5rem;
}
