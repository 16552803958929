.rol-ordl-itm-wrap {
  display: flex;
  background: white;
  color: #515151;
  font-family: Almoni;
  font-size: 16px;
  font-weight: 300;
  margin: 3px 0;
  padding: 2.5px 0;
}

.rol-ordl-itm-row-f {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rol-ordl-itm-round-btm-crnrs {
  border-radius: 0 0 10px 10px;
}
