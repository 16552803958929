.payment-input-card {
  width: 620px;
  height: 330px;
  margin-top: 1rem;
}
.payment-input-card .slot,
.payment-input-card .card,
.payment-input-card .arrowIn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-input-card .slot img {
  width: 200px;
}
.payment-input-card .card {
  position: relative;
  overflow: hidden;
  margin-top: -30px;
  align-items: center;
}
.payment-input-card .card img {
  display: block;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 10s;
  animation-name: cardslidein;
  animation-iteration-count: infinite;
  /* animation-delay: 2s; */
  margin-top: 50px;
}

@keyframes cardslidein {
  from {
    margin-top: 50px;
  }
  10% {
    margin-top: -50px;
  }
  95% {
    margin-top: -50px;
  }
  to {
    margin-top: 50px;
  }
}

.payment-input-card .arrowIn img {
  margin-top: 20px;
  width: 70px;
  /* animation-duration: 4s; */
  /* animation-name: ; */
  animation: arrowslidein 1.5s infinite linear;
}

@keyframes arrowslidein {
  0% {
    margin-top: 20px;
  }
  /* 5% {
    margin-top: 0;
  }
  15% {
    margin-top: 20px;
  } */
  50% {
    margin-top: 0;
  }

  100% {
    margin-top: 20px;
  }
}
