.sus2s-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sus2s-frame-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .sus2s-frame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 70vw;
} */

.sus2s-body {
  flex: 10;
}

.sus2s-footer {
  flex: 1;
}

.sus2s-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.sus2s-details-btns {
  width: 500px;
  /* height: 2rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sus2s-details-single-btn {
  font-size: 30px;
  margin: auto;
  margin-top: 2rem;
}

.sus2s-company-station-name-wrapper {
  display: flex;
  flex: 1;
}

.sus2s-wrapper .whlb-wrapper {
  font-size: 22px;
}
