.react-modal-content.scan-waybill-barcode-error {
  height: 70%;
  width: 60%;
  top: 15%;
  bottom: 15%;
  left: 20%;
  right: 20%;
}

.scan-waybill-barcode-error .errinm-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(70, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  /* z-index: 1; */
}

.scan-waybill-barcode-error .errinm-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
  max-width: 800px;
}

.scan-waybill-barcode-error .errinm-label-extra-class {
  text-align: center;

  font-size: 35px;
  font-weight: 800;
  padding: 10px 0;
}

.scan-waybill-barcode-error .error-text1 {
  font-size: 25px;
  font-family: Almoni;
}

.scan-waybill-barcode-error .error-info {
  display: flex;
  align-content: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.scan-waybill-barcode-error .error-info .error-info-text {
  display: flex;
  width: 247px;
  margin: 40px 17px 40px 17px;
  font-family: Almoni;
  font-size: 20px;
  font-weight: bold;
}
.scan-waybill-barcode-error .error-info .error-info-icon {
  display: flex;
  img {
    max-width: 170px;
    max-height: 170px;
  }
}

.scan-waybill-barcode-error .blbtn-wrapper {
  font-weight: bold;
  color: #fc262f;
  background-color: #ffcc01;
}
