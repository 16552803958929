.nos-frame-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.nos-frame-main-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 70vh;
}

.nos-frame-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: auto; */
}
.nos-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
  margin: -3rem auto 0 auto;
}
.nos-subtitle {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 16px;
  margin: 0 auto;
}

.nos-frame-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
/* .one-button-div {
} */
.two-buttons-div {
  display: flex;
  flex-direction: row;
}
.white-button {
  background-color: white;
  font-weight: 600;
  border: solid 1px #fc5726;
  color: #fc5726;
}
.white-button:active {
  background-color: #fc5726;
  border: none;
  color: white;
}

.two-buttons-div .nos-frame-single-btn {
  width: 283px;
}
.nos-frame-single-btn {
  /* padding: 5px 50px; */
  width: 290px;
  height: 40px;
  font-size: 30px;
}
/* .nos-frame-discount-wrapper {
  font-family: Almoni;
  display: block;
  text-align: left;
} */
/* .nos-frame-discount {
  display: inline-block;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  width: 300px;
} */
/* .nos-frame-discount-text {
  display: inline-block;
  padding: 0 10px;
} */
