.card-input-check-wrapper{
  margin-top: -1rem;
  margin-bottom: 5rem;
}
.check-card-NAYAX-text{
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  width: 70%;
  line-height: 1;
  text-align: center;
}
.card-check-NAYAX-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}