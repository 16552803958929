.rol-ordl-header-wrapper {
  background: #919191;
  border: 3px solid #919191;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rol-ordl-header-title-wrapper {
  color: #ffffff;
  font-family: Almoni;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  flex: 1;
}
.rol-itls-header-flex2 {
  flex: 2;
}
