.sus1s-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sus1s-frame-wrapper {
  display: flex;
  flex-direction: column;
  //margin-top: -2.7rem;
  margin-bottom: 1.7rem;
}

.sus1s-body {
  flex: 10;
}

.sus1s-footer {
  flex: 1;
}

.sus1s-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.sus1s-details-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
