.errinm-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(70, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  /* z-index: 1; */
}

.errinm-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
  max-width: 800px;
}

.errinm-label-extra-class {
  text-align: center;
  font-family: AlmoniTzar;
  font-size: 35px;
  font-weight: 800;
  padding: 10px 0;
}
.errinm-label-extra-class2 {
  font-size: 23px;
  font-family: Almoni;
  padding: 1rem 0 2rem 0;
}

.error-info-with-link-buttons {
  display: flex;
  align-items: center;
  align-content: center;
}
.error-info-with-link-buttons .blbtn-wrapper {
  width: auto;
  padding: 10px 26px;
  margin: 2rem 1rem 1rem 0;
}
.error-info-with-link-buttons .link-btn {
  font-family: AlmoniTzar;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: #fc5726;
  text-decoration: underline;
  padding: 10px 26px;
  margin: 2rem 1rem 1rem 0;
}
.react-modal-content.error-info-with-link {
  height: 60%;
  top: 20%;
  bottom: 20%;
  width: 60%;
  right: 20%;
  left: 20%;
}
