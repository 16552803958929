.app-qr-code-button {
  cursor: pointer;
  position: relative;
  display: flex;
  margin: -60px 0 -180px 0;
  align-self: center;
}

.app-qr-code-button .white-part {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: #fc5726 solid 2px;
  border-radius: 8px;
  padding: 0 16px;
  height: 84px;
}

.app-qr-code-button .app-info {
  font-size: 22px;
}

.app-qr-code-button .app-title {
  font-family: AlmoniTzar;
  font-size: 22px;
  font-weight: 600;
}

.app-qr-code-button .app-text {
  font-family: Almoni;
  font-size: 18px;
}

.app-qr-code-button .app-icons {
}

.app-qr-code-button .qr-code-icon {
  width: 152px;
  height: 152px;
}

.app-qr-code-button .apple-store-icon,
.app-qr-code-button .google-play-icon {
  margin: 0 16px 0 0;
}

.app-qr-code-button.ltr .apple-store-icon,
.app-qr-code-button.ltr .google-play-icon {
  margin: 0 0 0 16px;
}
