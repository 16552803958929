.svg-menu-button {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #fc5726;
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}
.text {
  margin: auto;
}
/* .svg-menu-button .content {
  border: 2px solid #fff;
  padding: 30px 14px;
  width: 230px;
  height: 194px;
  border-radius: 10px;
}
.svg-menu-button .content .text {
  font-family: Almoni;
  color: #fff;
  text-align: center;
  font-size: 21px;
  letter-spacing: 1.4px;
  padding-top: 24px;
}
.svg-menu-button .content .img {
  width: auto;
  height: 96px;
  background: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-menu-button .content .img img {
  width: 96px;
  height: 96px;
} */
