.lds-ripple {
  display: inline-block;
  position: relative;
  width: 320px;
  height: 320px;
}

.lds-ripple div {
  position: absolute;
  border: 8px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -1.5s;
}

.lds-ripple div:nth-child(3) {
  animation-delay: -3s;
}

.lds-ripple div:nth-child(4) {
  animation-delay: -4.5s;
}

@keyframes lds-ripple {
  0% {
    top: 140px;
    left: 140px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 290px;
    height: 290px;
    opacity: 0;
  }
}