.due-situation-message {
  cursor: pointer;
  position: relative;
  display: flex;
  margin: -60px 0 -180px 0;
  align-self: center;

  .white-part {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f69536;

    border-radius: 8px;
    padding: 0 16px;
    height: 94px;
    border: none;
  }
  .days-text {
    text-decoration: underline;
  }

  .app-info {
    font-size: 25px;
    max-width: 360px;
  }

  .app-info.he {
    max-width: 255px;
  }

  .qr-code-icon {
    width: 152px;
    height: 152px;
  }

  .app-title {
    font-family: AlmoniTzar;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }

  .delay-icon {
    margin: 0 0 0 16px;
  }
}

.due-situation-message.ltr {
  .delay-icon {
    margin: 0 16px 0 0;
  }
}
