.vkb-key-wrapper {
  height: 70px;
  width: 70px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  margin: 5px;
  font-size: 35px;
  font-weight: 800;
  font-family: AlmoniTzar;
  background: white;
  text-align: center;
  border-radius: 6px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fc5726;
}

.space-key {
  width: 140px;
}
.space-key img {
  margin: 0 4rem;
}
.eng img {
  height: 100px;
}

.vkb-key-wrapper:active {
  background: #fc5726;
  color: white;
}
