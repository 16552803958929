.prls-tabl-hdr-wrapper {
  flex: 1;
  color: #fc5726;
  text-align: center;
  font-family: Almoni;
  font-weight: 800;
  font-size: 20px;
  /* margin-top: 5rem; */
  /* background: #919191; */
  border-bottom: solid white 2px;
  border-radius: 10px 10px 0 0;
}

.prls-tabl-hdr-mini {
  font-size: 20px;
  /* border-bottom: solid white 1.5px; */
  /* border-radius: 3px; */
  /* background: #2faadf; */
}
