.pay-order-wrapper {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
}
.pay-order-wrapper .wait-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  color: #fc5726;
  font-size: 32px;
  font-weight: bold;
  justify-self: center;
  justify-content: center;
  align-items: center;
  height: 530px;
  padding: 180px;
}
.pay-order-wrapper .locker-wrapper-border {
  border: solid 1px #fff;
  border-radius: 12px;
  padding: 12px;
  margin: 5px 12px;
}

.pay-order-wrapper .cos-inner-container {
  display: flex;
  justify-content: space-between;
  height: 530px;
  margin-bottom: 2.5rem;
}

.pay-order-wrapper .summary-wrapper {
  border: solid 1px #fc5726;
  border-radius: 10px;
  height: 530px;
}

.cos-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 0px 300px;
  margin-top: 20px;
}

.cos-more-orders-label {
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  animation: more_orders_anim 3s ease-in-out infinite;
  line-height: 32px;
}

@keyframes more_orders_anim {
  0% {
    color: white;
    font-size: 30px;
  }
  50% {
    color: #fda73a;
    font-size: 32px;
  }
  100% {
    color: white;
    font-size: 30px;
  }
}
.collect-back-btn {
  margin-top: 2.5rem;
  direction: rtl;
}
.cos-column {
  display: flex;
  flex-direction: column;
  /* margin-right: -5rem; */
  width: 30vw;
}
.cos-table-total {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
}
.shipping-collect-order-cells {
  padding-right: 2rem;
  max-width: 55vw;
  width: fit-content;
}
.collect-submit-btn {
  width: 290px;
  margin: auto;
}

.order-number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  font-family: Almoni;
  font-weight: 600;
  font-size: 20px;
}
.pay-order-wrapper .discount-info,
.pay-order-wrapper .order-cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  font-family: Almoni;
  font-weight: 600;
  font-size: 20px;
  padding: 6px 0 12px 0;
}

.pay-order-wrapper .discount-info {
  font-size: 18px;
  padding: 6px 0 0 0;
  color: #515151;
  font-weight: normal;
}

.pay-order-wrapper.ls-wrapper-rtl div.rtl {
  text-align: right;
}
.pay-order-wrapper.ls-wrapper-rtl div.ltr {
  text-align: left;
}

.pay-order-wrapper.ls-wrapper-ltr div.rtl {
  text-align: right;
}
.pay-order-wrapper.ls-wrapper-ltr div.ltr {
  text-align: left;
}

.cos-total-text {
  text-align: end;
  justify-content: flex-end;
  align-items: flex-end;
}
