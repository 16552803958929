.rodl-itm-wrap {
  display: flex;
  color: black;
  border-radius: 10px;
  font-family: Almoni;
  font-size: 16px;
  font-weight: 300;
  margin: 3px 0;
  /* padding: 2.5px 0; */
  white-space: nowrap;
  direction: rtl;
  min-height: 41px;
}

.rodl-itm-row-f {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 6px 0 6px; */
  border-right: 1px solid #eee;
}
.rodl-itm-row-f:first-child {
  border-right: none;
}

.rodl-itm-round-btm-crnrs {
  border-radius: 0 0 10px 10px;
}

.rodl-frame-single-btn {
  font-size: 18px;
  min-width: 80px;
  width: 80px;
}
.rodl-frame-single-btn:active {
  font-size: 16px;
}

.rodl-orange {
  background-color: #fda73a;
  color: white;
}

.rodl-img {
  width: 30px;
}

.rold-itm-flex-2 {
  flex: 2;
}
