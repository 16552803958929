.user-menu-btn {
  width: 410px;
  height: 65px;
  padding-top: 0.35rem;
  font-weight: 600;
  font-size: 2.1rem;
  letter-spacing: 1.3px;
}
.user-menu-btn:active {
  width: 405px;
  height: 60px;
} 
.mbb-disabled {
  background: #ddab9b;
}

.mbb-disabled:active {
  background: #ddab9b;
}
.finish-rent-text{
  font-size: 24px;
}