.payment-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.payment2-wrapper{
    min-width: 620px; 
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.payment2-wrapper .c-container{
    margin: 20px 0 75px 0;
}