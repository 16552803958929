/* .collect-order-screen {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
}
.collect-order-screen .locker-wrapper-border {
  border: solid 1px #fff;
  border-radius: 12px;
  padding: 12px;
  margin: 5px 12px;
} */

.cos-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  width: 600px;
  height: 530px;
}
.cos-inner-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.cos-inner-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.cos-inner-wrapper::-webkit-scrollbar {
  width: 13px;
}
.cos-inner-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.cos-inner-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}

.collect-order-screen .cos-inner-container {
  display: flex;
  justify-content: space-between;
  height: 530px;
  margin-bottom: 2.5rem;
  /* border: solid 1px green; */
}

.cos-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 0px 300px;
  margin-top: 20px;
}

.cos-more-orders-label {
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  animation: more_orders_anim 3s ease-in-out infinite;
  line-height: 32px;
}

@keyframes more_orders_anim {
  0% {
    color: white;
    font-size: 30px;
  }
  50% {
    color: #fda73a;
    font-size: 32px;
  }
  100% {
    color: white;
    font-size: 30px;
  }
}
.collect-back-btn {
  margin-top: 2.5rem;
  direction: rtl;
}
.cos-column {
  display: flex;
  flex-direction: column;
  /* margin-right: -5rem; */
  width: 30vw;
}
.cos-table-total {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
}
/* .collect-order-cells {
  padding-right: 2rem;
  max-width: 55vw;
  width: fit-content;
} */
.collect-submit-btn {
  width: 290px;
  margin: auto;
}

/* .collect-order-screen .cos-order-num-text,
.collect-order-screen .cos-total-text {
  display: flex;
  text-align: center;
  font-family: AlmoniTzar;
  font-size: 24px;
  font-weight: 600;
} */
/* .collect-order-screen .cos-order-num-text div,
.collect-order-screen .cos-total-text div {
  flex: 1 1;
  vertical-align: middle;
} */
.order-number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  font-family: Almoni;
  font-weight: 600;
  font-size: 20px;
}
.discount-info,
.order-cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 310px;
  font-family: Almoni;
  font-weight: 600;
  font-size: 20px;
  padding: 0 0 6px 0;
}

.discount-info {
  font-size: 18px;
  padding: 6px 0;
  color: #515151;
  font-weight: normal;
}

.collect-order-screen.ls-wrapper-rtl div.rtl {
  text-align: right;
}
.collect-order-screen.ls-wrapper-rtl div.ltr {
  text-align: left;
}

.collect-order-screen.ls-wrapper-ltr div.rtl {
  text-align: right;
}
.collect-order-screen.ls-wrapper-ltr div.ltr {
  text-align: left;
}

.cos-total-text {
  text-align: end;
  justify-content: flex-end;
  align-items: flex-end;
}

@keyframes blink-btn {
  30% {
    background: #ff5726;
  }
  70% {
    background: #ff5926a4;
  }
}

.button-blink {
  animation: blink-btn 2s ease-in-out 0s infinite;
  border: solid 1px #ff5726;
}
