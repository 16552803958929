.fh-header {
  direction: rtl;
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
}

.customer-service {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 30rem;
  height: 3rem;
  font-family: AlmoniTzar;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.66;
  letter-spacing: -0.24px;
  text-align: right;
  color: #000;
  padding: 0 0 6px 0;
}

.customer-service .icon-app {
  padding: 0 4px;
}
.customer-service .support-phone {
  padding: 3px 4px;
}
.customer-service .space {
  width: 8px;
}

.header-buttons {
  position: relative;
  display: flex;
  align-items: center;
}

.header-button {
  font-family: AlmoniTzar;
  font-size: 32px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 0.46rem;
  color: #fc5726;
  cursor: pointer;
  margin-right: 2rem;
  white-space: nowrap;
}

.fh-header-big-title {
  font-size: 47px;
  font-weight: 400;
  white-space: pre-wrap;
}

.fh-header-sub-title {
  font-size: 22px;
  white-space: pre-wrap;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 47vw;
  height: 66vh;
  border-radius: 8px;
  overflow-y: hidden;
}
.modal-dialog {
  min-width: none;
  min-height: fit-content;
  margin: 0;
}
.big-modal {
  width: 90vw;
  height: 90vh;
  margin: 0;
}
.medium-modal {
  width: 60vw;
  height: 70vh;
}
.code-coupon-modal {
  width: 60vw;
  height: 40vh;
}
.wrong-coupon-modal {
  width: 40vw;
  height: 30vh;
}
.serv-details-modal {
  width: 50vw;
  height: 55vh;
}
.error-modal {
  width: 50vw;
  height: 47vh;
}

.code-coupon-modal-body {
}

.big-modal-close-btn {
  justify-self: flex-end;
  margin-top: -1rem;
}
.big-modal-body {
  height: 80vh;
}
.modal-header {
  border: none;
}
.modal-title {
  margin: 0 auto;
}
.modal-content {
  border: none;
  min-height: 65vh;
  max-height: 90vh;
  padding: 1rem;
}
.big-modal-content {
  height: 90vh;
}
.price-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;
  height: 80%;
}
.price-modal-body .price-table {
  height: 100%;
}

.pricelist-title {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 32px;
  /* margin-top: -1rem; */
}
.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.price-modal-body::-webkit-scrollbar {
  margin-left: 50px;
  width: 13px;
}

.price-modal-body::-webkit-scrollbar-thumb {
  background-color: #fc5726;
  border-radius: 11px;
}
.price-modal-body::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.column-modal-footer {
  flex-direction: column;
  border: none;
}
.modal-footer {
  padding: 0;
}

.close-btn {
  margin-top: 2rem;
  height: 2.5rem;
  width: 12rem;
  color: white;
  font-size: large;
  font-weight: 600;
  align-self: center;
  background-color: #fc5726;
  border: none;
  border-radius: 8px;
}
.close-btn:hover {
  background-color: #f1724c;
}
.close-btn:active {
  height: 2.4rem;
  width: 11.8rem;
}
