.more-info {
  align-self: center;
  margin-top: auto;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: 300;
}
.click-here {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}
