.vkb-wrapper {
  align-self: center;
  bottom: 400px;
  justify-self: flex-start;
  direction: ltr;
  user-select: none;
}

.vkb-keys-layout-wrapper {
  position: fixed;
  background: #d2d2d2;
  padding: 10px;
  margin: 5px;
  bottom: 1rem;
  right: 23.5rem;
  line-height: 50px;
  font-size: 50px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 1px 0 15px 0 rgba(194, 194, 194, 0.72);
  border: solid 1px rgba(255, 255, 255, 0);
  background-color: #d2d2d2;
  z-index: 2;
}
.right-position {
  bottom: 3rem;
  right: 10rem;
}
.vkb-marg-bot {
  bottom: 70px;
}
.top-position {
  bottom: 1rem;
}
.vkb-close-key {
  font-weight: 600;
}
.bot-position {
  bottom: 0.2rem;
}

.vkb-close-key-wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.big-keyboard {
  bottom: 0;
  right: 0;
  width: 100vw;
  height: fit-content;
  margin: 0;
  border-radius: 0;
  z-index: 100;
}
