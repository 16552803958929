.ooalo-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
}

.ooalo-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 25px 65px;
}
.orange-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem auto;
  height: 130px;
  width: 130px;
  border: 13px solid #fc5726;
  border-radius: 50%;
}
.orange-stick {
  width: 13px;
  height: 50px;
  background-color: #fc5726;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}
.orange-dot {
  width: 13px;
  height: 13px;
  background-color: #fc5726;
  border-radius: 8px;
}

.ooalo-label-extra-class {
  text-align: center;
  font-family: AlmoniTzar;
  font-size: 30px;
  font-weight: 300;
  line-height: 1;
}

.ooalo-label-extra-class2 {
  margin-bottom: 0.2rem;
}
.wardrobe {
  height: 200px;
  margin: 1rem;
}
.ooalo-close-btn {
  margin: 0 auto;
}

.ooalo-close-btn p {
  height: 100%;
  margin: auto;
}
