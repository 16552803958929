.whlb-wrapper {
  color: #000;
  font-family: Almoni;
  font-size: 25px;
  font-weight: 500;
  user-select: none;
}

.whlb-on-active-effect:active {
  color: #fda73a;
}
