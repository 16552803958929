.app-qrcode-screen .white-part {
  height: auto;
  border-radius: 8px;
  padding: 2px;
  display: inline-block;
}
.app-qrcode-screen .app-qrcode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -2rem;
}

.app-qrcode-screen .scann-app-qrcode-text h1 {
  font-family: AlmoniTzar;
  font-size: 40px;
  font-weight: bold;
  padding: 20px 0;
}

.app-qrcode-screen .woman-laundry-baby {
  margin: 0 -26px -96px 0;
}
.app-qrcode-screen .man-cell-phone-icon {
  margin: 0 27px -72px -2px;
}
