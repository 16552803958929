.sus3s-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sus3s-body {
  flex: 10;
}

.sus3s-footer {
  flex: 1;
}

.sus3s-frame-wrapper {
  display: flex;
  flex-direction: column;
}

.sus3s-frame-container {
  display: flex;
  justify-content: center;
}

.sus3s-frame-labels-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.sus3s-details-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px 0 0 0;
}

.sus3s-details-single-btn {
  font-size: 30px;
}

.sus3s-frame-checkbox-and-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sus3s-frame-instruction-text {
  margin: 0 8px;
}

.sus3s-swipe-your-card {
  margin: 20px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.sus3s-swipe-your-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sus3s-swipe-your-card-label {
  font-size: 30px;
  text-align: center;
  margin: 0 15px;
}

.sus3s-retry-btn {
  font-size: 30px;
  margin: 10px 200px;
}
