.maint-wrapper {
  background: rgba(0, 0, 0, 0.85);
  flex: 1;
  display: flex;
  font-size: 40px;
  line-height: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: AlmoniTzar;
}

.maint-msg {
  border: solid #fc5726 3px;
  background-color: white;
  border-radius: 20px;
  margin: 50px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader-wrapper {
  margin-top: 3rem;
}
