.nayax-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: -2rem;
}
.card-reader-3-wrapper{
    width: 650px;
    height: 450px;
    display: flex;
    margin-top: 1rem;
    margin-bottom: -9rem;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
.card-reader-3-1{
    margin-top: 1rem;
    height: 300px;
}
.card-reader-3-2{
    margin-top: 1rem;
    height: 220px;
}
.reader-with-title{
    font-family: almoniTzar;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.card-reader-3-title{
    font-family: AlmoniTzar;
    font-size: 40px;
    font-weight: 300;
    margin: 0 auto 1rem auto;
}
.cancel-loader-title{
    font-family: AlmoniTzar;
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 3rem;
}