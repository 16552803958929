.order-details-wrapper{
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.order-details-title{
    margin-top: 1rem;
    align-self: center;
font-size: 45px;
font-weight: 600;
font-family: AlmoniTzar;
}
.order-details-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 3rem;
    
}
.title-in-list{
    margin: 0.5rem 0;
    font-size: 1.7rem;
    font-weight: 600;
    font-family: AlmoniTzar;
}
.order-detail{
    margin: 0 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Almoni;
}
.barcode-row{
    position: fixed;
    top: 47.6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    width: 1024px;
    height: 60px;
  }
  .collect-input-barcode{
    width: 360px;
    padding: 2px 17px;
    border-radius: 8px;
    font-size: 1.7rem;
    font-weight: 600;
    background-color: white;
    letter-spacing: 10px;
    border: 2px solid #fda73a;
    margin-left: 2rem;
    font-family: Almoni;
  }
  .collect-modal-move-up{
      margin-top: -7rem;
  }
