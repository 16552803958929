.itls-header-wrapper {
  background: #fff;
  width: 343px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.itls-header-title-wrapper {
  color: #fc5726;
  font-family: AlmoniTzar;
  font-size: 25px;
  font-weight: 600;
  flex: 1;
  text-align: center;
}

.itls-header-flex2 {
  flex: 2;
}
