.bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 2px 1px 0 1px white;
    animation: spin 8s linear infinite;
}

.bubble-fill {
    /* ANOTHER EFFECT INSTEAD OF REGULAR BACKGROUND.. */
    /* box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1); */
    width: 170px;
    height: 170px;
    background: white;
    border-radius: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}