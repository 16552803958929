.itsqpur-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 450px;
    overflow: auto;
}

.itsqpur-inner-wrapper {
    display: flex;
    flex-direction: row;
}

.itsqpur-wrapper::-webkit-scrollbar {
    width: 12px;
}

.itsqpur-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #1076ba;

}

.itsqpur-wrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}