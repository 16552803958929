.rodl-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  margin: 1rem 0 -1rem 0;
  direction: rtl;
}

.rodl-header-title-wrapper {
  font-family: Almoni;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  flex: 1;
}
.move-right {
  margin-left: 2rem;
}
