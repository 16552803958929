.ordl-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ordl-header-title-wrapper {
  color: #fc5726;
  /* padding: 6px; */
  font-family: AlmoniTzar;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  flex: 1;
}
