.loadinfmodal-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
}

.loadinfmodal-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
}

.loadinfmodal-label-extra-class {
  font-family: AlmoniTzar;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  padding: 10px 0;
}

.loadinfmodal-label-extra-class2 {
  font-size: 25px;
}

.loadinfmodal-close-btn {
  padding: 0 35px;
  margin-top: 30px;
  font-size: 25px;
}
