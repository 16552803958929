.thanks-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}
.thanks-img {
  width: 460px;
  height: 360px;
  font-family: Almoni;
}
