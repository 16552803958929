.react-modal-content.order-qr-content-wrapper {
  top: 7%;
  bottom: 7%;
  height: 86%;
}
.order-qr-wrapper {
  .order-details-title {
    margin-top: 1rem;
    align-self: center;
    font-size: 45px;
    font-weight: 600;
    font-family: AlmoniTzar;
  }
  .order-details-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .order-details-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 3rem;
  }

  .title-in-list {
    margin: 0.1rem 0;
    font-size: 1.7rem;
    font-weight: 600;
    font-family: AlmoniTzar;
  }
  .order-detail {
    margin: 0 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Almoni;
  }
}
