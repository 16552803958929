.manual-package-search {
  top: 35% !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  width: 100% !important;
  height: 15% !important;

  .dms-title {
    margin: 1.5rem 0 2rem 0;
    font-size: 38px;
  }
  .barcode-row {
    position: fixed;
    top: 47.6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    width: 1024px;
    height: 60px;
  }
  .collect-input-barcode {
    width: 360px;
    padding: 2px 17px;
    border-radius: 8px;
    font-size: 1.7rem;
    font-weight: 600;
    background-color: white;
    letter-spacing: 10px;
    border: 2px solid #fda73a;
    margin-left: 2rem;
    font-family: Almoni;
  }
  .collect-modal-move-up {
    margin-top: -7rem;
  }
}
