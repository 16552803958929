.rol-inner-wrapper {
  display: flex;
  justify-content: center;
}

.rol-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 0px 300px;
  margin-top: 20px;
}
.rol-frame-container {
  display: flex;
  justify-content: center;
  /* background: red; */
}

.rol-frame-inner-wrapper {
  display: flex;
}

.rol-frame-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.rol-frame-single-btn {
  font-size: 25px;
}
