.whsq-wrapper {
  height: 185px;
  width: 185px;
  border: solid 1px #fc5726;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: white;
  font-size: 24px;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 5px;
  font-family: AlmoniTzar;
  font-weight: 600;
  user-select: none;
  padding-bottom: 0.7rem;
}

.whsq-img {
  height: 100%;
  /* margin-bottom: 2.5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.whsq-img img {
  height: 120px;
}
.whsq-wrapper:active {
  background-color: rgba(252, 87, 38, 0.1);
}
