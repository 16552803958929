.locker-to-locker-delivery .request-order-item {
  display: flex;
  color: black;
  border-radius: 10px;
  font-family: Almoni;
  font-size: 16px;
  font-weight: 300;
  margin: 6px 12px;
  white-space: nowrap;
  direction: rtl;
}

.locker-to-locker-delivery .request-order-item .column {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.locker-to-locker-delivery .request-order-item .column.order-number {
  font-weight: bold;
  font-size: 20px;
}

.locker-to-locker-delivery .request-order-item .column.info {
  flex: 4;
  direction: ltr;
}

.locker-to-locker-delivery .request-order-item .column.trash:last-child {
  flex: none;
  width: 50px;
  height: 50px;
  align-self: center;
  padding: 3px 6px;
  border-right: 3px solid #fff;
  cursor: pointer;
}

.locker-to-locker-delivery .request-order-item .column:first-child {
  border-right: none;
}

.locker-to-locker-delivery .continue .blbtn-wrapper.continue-btn {
  min-width: 118px;
  max-width: 128px;
  font-size: 27px;
}
