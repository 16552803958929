.payment-wait-card {
  width: 620px;
  height: 300px;
}

.payment-wait-card .slot,
.payment-wait-card .card,
.payment-wait-card .wait-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-wait-card .slot img {
  width: 200px;
}
.payment-wait-card .card {
  position: relative;
  overflow: hidden;
  padding-bottom: 55px;
  margin-top: -30px;
}

.payment-wait-card .card .image-wrapper img {
  width: 180px;
  margin-top: -50px;
}
.payment-wait-card .wait-circle img {
  width: 80px;
  height: 80px;
  animation: animate 3s linear infinite;
}
@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}
