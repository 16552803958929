.barcode-reader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.barcode-reader-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
}
.barcode-reader {
  width: 230px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.barcode-reader img {
  width: 99%;
  height: 99%;
}
