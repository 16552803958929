.ls-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}

.ls-frame-wrapper {
  display: flex;
  flex-direction: column;
}

.ls-frame-container {
  display: flex;
  justify-content: center;
}

.ls-footer {
  flex: 1;
}

.ls-frame-labels-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ls-frame-label1 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-frame-label2 {
  white-space: nowrap;
  font-size: 25px;
  padding: 3px 15px;
}

.ls-frame-label3 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-temporary-text {
  margin-top: -15px;
  font-size: 25px;
  text-align: center;
  font-family: Almoni;
  font-weight: 600;
  color: red;
}
.buttons-login-screen {
  height: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 0 7rem;
}
.otp-doneid-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.otp-doneid-btn-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-doneid-btn-man {
  position: absolute;
  left: 40px;
  bottom: 84px;
  height: 170px;
  /* top: 0; */
}
.otp-doneid-btn-woman {
  position: absolute;
  right: 40px;
  bottom: 84px;
  height: 170px;
  /* top: 0; */
}
.otp-doneid-btn-QR-code {
  position: absolute;
  left: 60px;
  top: 35px;
}
.asterisks {
  position: absolute;
  font-family: AlmoniTzar;
  font-weight: 600;
  color: #fff;
  right: 30px;
  top: 40px;
  letter-spacing: 0.5rem;
  font-size: 50px;
}
.otp-doneid-btn-inner-txt {
  position: absolute;
  font-size: 32px;
  font-family: AlmoniTzar;
  font-weight: 600;
  text-align: center;
  bottom: 12px;
}
.otp-doneid-btn-txt {
  margin-bottom: 1rem;
  font-size: 40px;
  font-family: AlmoniTzar;
  font-weight: 600;
}

.back-btn {
  margin: 10px auto;
  width: 30vw;
}
.login-button {
  margin: 0.3rem auto 17rem auto;
  width: 268px;
}
.password-tel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-title {
  align-self: center;
  margin-bottom: 1rem;
  font-family: AlmoniTzar;
  font-size: 44px;
  font-weight: 600;
  color: black;
}
.login-inputs-and-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 9.5rem;
}
.done-id-and-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -5rem;
}
