.retry-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.retry-text {
  margin-top: 4rem;
  font-size: 32px;
  font-weight: 600;
  font-family: AlmoniTzar;
}
.rent-titles-margin {
  margin-top: 0;
}
.rent-locker-title {
  display: flex;
  font-size: 32px;
  font-weight: 600;
  margin: -3rem auto -2rem auto;
}
.logo-service-icon {
  position: fixed;
  top: 143px;
  right: 63px;
  padding: 9px 7px;
  z-index: 1;
}
.logo-service-icon.dhl {
  background-color: #ffcc01;
}
