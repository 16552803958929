.lsp-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  margin-right: 4rem;
  z-index: 1;
}

.lng-button {
  color: black;
  font-weight: 800;
  font-size: 1.5rem;
}
.lng-button:hover {
  cursor: pointer;
}

.active-lng {
  color: #fc5726;
}
.divider {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: x-large;
}
