.bprg-outer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -30px auto 10px auto;
}

.bprg-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bprg-circle {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: solid rgba(255, 255, 255, 0.65) 1px;
  border-radius: 50%;
  background: #ffcdbe;
  color: #000;
  font-family: AlmoniTzar;
  font-size: 24px;
  font-weight: 300;
  margin: 0 3px;
}

.bprg-orange-line {
  justify-self: stretch;
  height: 4px;
  min-width: 150px;

  background: #ffcdbe;
}

.momo {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  vertical-align: center;
}

.bprg-curr-val {
  background-color: #fc5726;
}
