.sms-login-screen .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: -20rem;
  /* width: 266px; */
  /* margin: auto; */
  /* flex-wrap: wrap */
}
/* .sms-login-screen .input-wrapper {
  justify-items: center;
  border-radius: 8px;
  background-color: #1076ba;
  padding: 14px;
} */
.op-login-btn-wrapper {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.sms-login-screen .keyboard-wrapper {
  position: absolute;
  top: 40rem;
  left: 63.55rem;
}

.sms-login-screen .keyboard-wrapper .vkb-wrapper {
  bottom: 0;
}
.sms-login-screen .keyboard-wrapper .vkb-absolute {
  position: relative;
  padding: 0;
}
.sms-login-screen .keyboard-wrapper .vkb-absolute .vkb-keys-layout-wrapper {
  margin: 5px 0;
}
.sms-login-screen .content-wrapper .blbtn-wrapper {
  font-size: 24px;
  padding: 3px;
  margin: 0;
  border-radius: 10px;
}
.sms-login-screen .vkb-key-wrapper.special-key {
  width: 38px;
}
