.done-password-login-wrapper {
  display: flex;
  margin-top: -4rem;

  .enter-text {
    font-family: AlmoniTzar;
    font-weight: bold;
    font-size: 40px;
    width: 400px;
    text-align: center;
    margin: 0 0 1.5rem 0;
  }

  .login-btn {
    width: 268px;
  }

  .laundry-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 auto -0.5rem;
  }
  .validation-wrapper {
    top: -25px;
  }

  .vkb-keys-layout-wrapper {
    right: 10.7rem;
    bottom: 1.5rem;
    .big-keyboard {
      right: 0;
      bottom: 0;
    }
  }
}

.step2_EnterPwd .done-password-login-wrapper {
  .vkb-keys-layout-wrapper {
    right: 0;
    bottom: 0;
  }
}
.update-password .ls-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enter-text h3 {
  font-family: Almoni;

  font-size: 16px;
}

.update-password .ls-frame-wrapper {
  display: flex;
  flex-direction: column;
}

.ls-frame-container {
  display: flex;
  justify-content: center;
}

.update-password .ls-body {
  flex: 10;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.ls-footer {
  flex: 1;
}

.ls-frame-labels-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ls-frame-label1 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-frame-label2 {
  white-space: nowrap;
  font-size: 25px;
  padding: 3px 15px;
}

.ls-frame-label3 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-temporary-text {
  margin-top: -15px;
  font-size: 25px;
  text-align: center;
  font-family: Almoni;
  font-weight: 600;
  /* color: #1076ba; */
  color: red;
}
.back-btn {
  margin: 10px auto;
  width: 30vw;
}

.not-used.lundry-login .keyboard-wrapper {
  position: relative;
}
.not-used.lundry-login .vkb-wrapper.vkb-absolute {
  position: relative;
  bottom: 0;
}

.step1_EnterPhone.lundry-login .vkb-wrapper.vkb-absolute {
  bottom: 110px;
}

.step2_EnterPwd.lundry-login .bf-wrapper.top-90 {
  margin: 10px 0 0 0;
}
