.barcode-screen-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.dlacs-inner-wrapper {
  display: flex;
  height: 400px;
  overflow: auto;
  margin-top: -5rem;
}
.dlacs-inner-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.dlacs-inner-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.dlacs-inner-wrapper::-webkit-scrollbar {
  width: 13px;
}
.dlacs-inner-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.dlacs-inner-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}

.dlacs-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 0px 300px;
  margin-top: 20px;
}
.dlacs-cells {
  height: 60vh;
  width: 90vw;
  padding-bottom: 1rem;
  /* justify-content: center; */
}
.dlacs-frame-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.dlacs-frame-single-btn {
  font-size: 25px;
}
.driver-screen-title {
  margin: -7rem auto 0 auto;
  font-size: 32px;
  font-weight: 600;
}
.driver-screen-subtitle {
  font-size: 24px;
  font-weight: 300;
  margin: -2rem auto 0 auto;
}

.dlacs-frame-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.no-orders-text {
  font-family: Almoni;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}
.load-clean-orders .barcode-wrapper {
  position: relative;
}

.load-clean-orders .barcode-wrapper .barcode-input {
  position: absolute;
  width: 200px;
}

.white-button-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  margin: 10px 0 -140px 0;
  align-self: center;
}
