.card-insert-wrapper {
  height: 300px;
}
.check-card-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 4rem;
}
.check-card-text {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: -1rem;
  width: 70%;
  line-height: 1;
  text-align: center;
}
.check-card-close-btn{
  margin: 1rem 1rem auto auto;
  color:#fc5726 ;
  font-size: 32px;
  font-weight: 600;
  border: none; 
  background-color: white;
}
.card-check-manual-wrapper{
  margin: auto;
  margin-top: -5rem;
}
.manual-input-btn-nayax{
  margin-top: -2rem;
}
.cardCheck-btn-mt{
 margin-bottom: 3rem;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.card-check-spinner{
  margin-bottom: 6rem;
}
.card-check-reader{
  margin: -5rem auto -2.5rem auto;
}