.soda-bg-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    top: 0;
    left: 0;
}

.soda-bg-bubble {
    position: absolute;
    /* bottom: -100px; */
    /* opacity: 0.5; */
    animation: flying 10s infinite ease-in;
}

@keyframes flying {
    0% {
      bottom: -200px;
      transform: translateX(0);
    }
    50% {
      transform: translateX(100px);
    }
    100% {
      bottom: 1080px;
      transform: translateX(-200px);
    }
  }