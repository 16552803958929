.input-wrapper {
  position: relative;
  display: inline-block;
  input {
    //width: 400px;
    width: 270px;
    height: 60px;
    font-family: AlmoniTzar;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0);
    position: relative;
    text-align: center;
    padding: 0 0.2rem;
    margin: 2px 8px;
    outline: none;
    caret-color: #fc5726;
  }
}

.input-wrapper input:focus,
.input-wrapper.error input {
  border: 1px solid #fc5726;
}
.input-wrapper.error input:focus,
.error-wrapper .input-wrapper input:focus {
  border: 1px solid #fc262f;
}

input::placeholder {
  color: #b3adad;
  font-size: 36px;
  text-align: center;
  line-height: 60px;
}
.step1 {
  position: relative;

  input {
    /* width: 270px;
    height: 60px;
    font-family: AlmoniTzar;
    font-size: 60px;
    font-weight: bold;
    border: none;
    color: rgba(0, 0, 0);
    position: relative;
    outline: none;
    padding: 0 0.2rem;
    text-align: center;
    outline: none;*/
    width: 270px;
    height: 60px;
    font-family: AlmoniTzar;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0);
    position: relative;
    text-align: center;
    padding: 0 0.2rem;
    margin: 2px 8px;
    outline: none;
    caret-color: #fc5726;
  }
}

.step1 input:focus,
.step1.error input {
  border: 1px solid #fc5726;
}
.step1.error input:focus {
  border: 1px solid #fc262f;
}

input::placeholder {
  color: #b3adad;
  font-size: 36px;
  text-align: center;
  line-height: 60px;
}
.sms-code-input {
  text-align: center;
}

.cardNumber input {
  font-size: 40px;
}

.input-wrapper .infl-wrapper.infl-ltr {
  background-position: left 16px center;
}

.infl-rtl {
  direction: rtl;
}

.infl-load {
  animation: borderChange 1s infinite ease-in;
}

.validation-wrapper {
  position: absolute;
  //right: 75px;
  //top: -18px;
  font-weight: 600;
  font-family: Almoni;
  font-size: 16px;
  width: 270px;
  //color: #fc5726;
  color: #fc262f;
  text-align: center;
}

//.step1 .validation-wrapper {
//width: 310px;
// right: 70px;
//top: -10px;
//}

.input-wrapper .password-eye {
  position: absolute;
  right: -4rem;
  top: 1.3rem;
  cursor: pointer;
}
.step1 .password-eye {
  position: absolute;
  right: -3rem;
  top: 0.5rem;
  cursor: pointer;
  z-index: 1;
}
.psw-upd-wrapper {
  width: 300px;
  height: 60px;
  font-family: AlmoniTzar;
  font-size: 60px;
  font-weight: bold;
  border: none;
  color: rgba(0, 0, 0);
  position: relative;
  outline: none;
  padding: 0 2rem;
  text-align: center;
  outline: none;
}
.password-eye img {
  width: 40px;
  height: 40px;
}
