.user-menu {
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.ums-wrapper {
  display: flex;
  flex-direction: column;
  /* margin: auto; */
  align-self: center;
  margin-top: 12rem;
  /* justify-content: center; */
  /* align-items: center; */
}
.ums-wrapper .user-menu-btn {
  align-self: center;
}

.ums-title {
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
  margin: 7rem auto 0 auto;
}
.ums-subtitle {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 16px;
  margin: 0 auto -10rem auto;
}
.user-menu .ums-title {
  margin: 5rem auto 0 auto;
}

.user-menu .requests-list {
  max-height: 210px;
  overflow: auto;
}

.user-menu .requests-list::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.user-menu .requests-list::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.user-menu .requests-list::-webkit-scrollbar {
  width: 13px;
}
