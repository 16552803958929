.update-password .ls-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enter-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
.enter-text h1 {
  font-family: AlmoniTzar;
  font-weight: 300;
  font-size: 40px;
}
.login-btn {
  width: 268px;
}
.enter-text h3 {
  font-family: Almoni;

  font-size: 16px;
}

.laundry-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -10rem;
}

.update-password .ls-frame-wrapper {
  display: flex;
  flex-direction: column;
}

.ls-frame-container {
  display: flex;
  justify-content: center;
}

.update-password .ls-body {
  flex: 10;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.ls-footer {
  flex: 1;
}

.ls-frame-labels-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ls-frame-label1 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-frame-label2 {
  white-space: nowrap;
  font-size: 25px;
  padding: 3px 15px;
}

.ls-frame-label3 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-temporary-text {
  margin-top: -15px;
  font-size: 25px;
  text-align: center;
  font-family: Almoni;
  font-weight: 600;
  /* color: #1076ba; */
  color: red;
}
.back-btn {
  margin: 10px auto;
  width: 30vw;
}

.not-used.lundry-login .keyboard-wrapper {
  position: relative;
}
.not-used.lundry-login .vkb-wrapper.vkb-absolute {
  position: relative;
  bottom: 0;
}

.step1_EnterPhone.lundry-login .vkb-wrapper.vkb-absolute {
  bottom: 110px;
}

.step2_EnterPwd.lundry-login .bf-wrapper.top-90 {
  margin: 10px 0 0 0;
}
