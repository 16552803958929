.fixed-right-bottom-wrapper {
  position: fixed;
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

.fixed-right-bottom-wrapper .fixed-right-bottom-button {
  padding: 0 52px;
  height: 44px;
  background-color: #1076ba;
  opacity: 70%;
  border-radius: 12px 0 0 0;
  text-align: center;
  font-family: Almoni;
  font-size: 21px;
  line-height: 44px;
  color: #fff;
  cursor: pointer;
  z-index: 2;
}
