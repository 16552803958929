.oids-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 600px;
  width: 90vw;
  overflow: auto;
}

.oids-text-instructions-wrapper {
  font-size: 20px;
  font-family: Almoni;
  color: white;
  margin: 10px;
  padding: 20px 0;
}

.oids-cancel-btn {
  padding: 5px 10px;
  font-size: 25px;
  margin: 0 150px;
}
.oids-inner-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.oids-inner-wrapper::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.oids-inner-wrapper::-webkit-scrollbar {
  width: 13px;
}
.oids-inner-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.oids-inner-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}
