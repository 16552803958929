.dis-enb-btn {
  //background: #ff8c69;
  background: rgba(252, 98, 43, 0.2);
  width: 230px;
  height: 40px;
  color: rgba(252, 87, 38, 0.5);
  font-weight: 600;
}

.dis-enb-btn:active {
  background: rgba(252, 98, 43, 0.2);
  color: rgba(252, 87, 38, 0.5);
  //background: #ff8c69;
}
.dis-enb-btn:hover {
  background: rgba(252, 98, 43, 0.2);
  color: rgba(252, 87, 38, 0.5);
}
