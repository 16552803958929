.paymdl2-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
}

.paymdl2-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  min-width: 400px;
  flex: 1;
}

.paymdl2-close-title-section {
  display: flex;
  flex-direction: column;
}

.payment-modal-title {
  font-family: AlmoniTzar;
  text-align: center;
  font-size: 50px;
  font-weight: 300;
  margin: 0 0 15px 0;
}
.payment-method-btn {
  width: 180px;
  height: 180px;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  background-color: white;
  border: 1px solid #fc5726;
  border-radius: 8px;
}
.payment-method-btn img {
  margin-bottom: 1.5rem;
}

.payment-btns {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* border: #fc5726 2px solid; */
}
.payment-modal-close-btn {
  margin-top: 4rem;
}
.paymdl2-payinput-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-or-success-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paymdl2-enter-carddate-manually-btn {
  font-size: 25px;
  padding: 2px 25px;
  margin: 15px;
}

.paymdl2-manual-month-year-wrapper {
  display: flex;
  margin: 15px 0;
}

.paymdl2-month-year-label {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  align-self: center;
  justify-self: center;
  margin: 0 20px;
}

.paymdl2-blue-select {
  /* max-height: 10px; */
  /* max-width: 200px; */
  /* padding: 0 10px; */
}

/* .paymdl2-token-div-wrapper {
    padding: 10px;
    border-radius: 8px;
    border: solid 2px white;
    margin: 25px 15px;
} */
/* 
.paymdl2-manual-ok-btn {
  font-size: 30px;
  padding: 2px 25px;
  margin: 25px 150px;
  margin-bottom: 5px;
  font-weight: 700;
} */

.paymdl2-fade-in {
  animation: fadein 3s;
  text-align: center;
  /* padding-top: 20px; */
}

.paymdl2-err-btn {
  font-size: 30px;
  margin: 1rem auto;
}

.paymdl2-err-msg {
  padding: 20px 0 0 0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.paymdl2-save-token-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
