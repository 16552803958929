.wfll-details-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
}

.wfll-details-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  align-items: center;
}

.wfll-label-extra-class {
  font-family: AlmoniTzar;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding: 10px 0;
}

.wfll-close-extra-class {
  align-self: flex-start;
  padding-bottom: 10px;
}

.wfll-unresp-door-extra-class {
  font-weight: 600; 
  color: red;
  text-decoration: underline;
}

.wfll-details-order {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  padding: 20px;
  border: solid 3px white;
  flex: 1;
}

.wfll-details-order-wrapper {
  min-width: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
}

.wfll-details-order-bold {
  font-weight: 800;
}

.wfll-oops-btn {
  font-size: 27px;
  padding: 10px 15px;
}
