.ordl-itm-wrap {
  display: flex;
  background: rgba(194, 194, 194, 0.23);
  color: #515151;
  font-family: Almoni;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  margin: 3px 0;
  height: 48px;
}

.ordl-itm-row-f {
  text-align: center;
  border-left: solid 1px white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
