.rol-itls-wrapper {
    min-width: 800px;
}
.rol-odl-order-wrapper{
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    background: #515151;
    border: 3px solid #515151;
    color: #fff;
    border-radius: 10px 10px 0 0 ;
}
.rol-odl-order-status{
    display: flex;
    justify-content: flex-start;

    
}
.rol-itls-itm-row-flex2 {
    flex: 2;
}