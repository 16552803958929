.sms-login-screen {
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: -20rem;
    /* width: 266px; */
    /* margin: auto; */
    /* flex-wrap: wrap */
  }
  /* .sms-login-screen .input-wrapper {
  justify-items: center;
  border-radius: 8px;
  background-color: #1076ba;
  padding: 14px;
} */
  .input-wrapper {
    margin-bottom: 0.5rem;
    input {
      border-radius: 8px;
      width: 300px;
      background-color: #ffffff;
      font-size: 60px;
      height: 60px;
      margin: 0;
      padding: 10px 15px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      outline: none;
      direction: rtl;
    }
  }

  .keyboard-wrapper {
    position: absolute;
    top: 40rem;
    left: 63.55rem;

    .vkb-wrapper {
      bottom: 0;
    }
    .vkb-absolute {
      position: relative;
      padding: 0;
    }

    .vkb-absolute .vkb-keys-layout-wrapper {
      margin: 5px 0;
    }
  }

  .content-wrapper .blbtn-wrapper {
    font-size: 24px;
    padding: 3px;
    margin: 0;
    border-radius: 10px;
  }
  .vkb-key-wrapper.special-key {
    width: 38px;
  }
}
