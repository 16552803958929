.continue-btn {
  width: 185px;
}

.manual-waybill-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 16rem 0;
  direction: rtl;

  .title-text {
    padding: 0 0 10px 0;
  }

  .title-text h1 {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
  }

  .logo-service-icon {
    top: 131px;
  }

  .input-wrapper input {
    direction: ltr;
    text-align: center;
    border: 0;
    width: 550px;
  }

  .validation-wrapper {
    right: 160px;
  }

  .underline-wrapper {
    width: 344px;
    margin: -1rem 0 0 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    direction: ltr;
    z-index: 1;

    .underline {
      display: inline-block;
      height: 1px;
      margin: 0 0 0 12px;
      border-bottom: solid 2px #fc622b;
      width: 333px;
    }
  }

  .underline-wrapper.exelot {
    width: 512px;
    margin: -0.5rem 0 0.5rem 0;
    .underline {
      display: block;
      width: auto;
      margin: 0 0 0 19px;
    }
  }
  .icon-info-container.dhl {
    position: absolute;
    left: 72px;
    top: 114px;
    direction: ltr;
    z-index: 1;

    .rectangle-barcode-icon {
      position: absolute;
      margin: 0;
    }
    .ray-shadow-icon {
      position: absolute;
      top: 0.2rem;
      right: -17.6rem;
    }
    .rounded-barcode-icon {
      position: absolute;
      top: 3rem;
      right: -17rem;
      z-index: 3;
    }
  }

  .icon-info-container.exelot {
    position: absolute;
    left: 2.3rem;
    top: 2.2rem;
    direction: rtl;
    z-index: 1;

    .icon-info-inner-container {
      width: 240px;
      height: 206px;
      position: relative;
      .exelot-barcode-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
      .exelot-barcode {
        position: absolute;
        top: 0;
        left: 1.5rem;
        z-index: 3;
      }

      .exelot-ray {
        position: absolute;
        top: 1rem;
        right: 0;
        z-index: 1;
      }
    }
  }
}

.manual-waybill-container.ltr {
  direction: ltr;
}

.ls-frame-container {
  display: flex;
  justify-content: center;
}

.ls-footer {
  flex: 1;
}

.ls-frame-labels-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ls-frame-label1 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-frame-label2 {
  white-space: nowrap;
  font-size: 25px;
  padding: 3px 15px;
}

.ls-frame-label3 {
  font-size: 20px;
  padding: 3px 35px;
}

.ls-temporary-text {
  margin-top: -15px;
  font-size: 25px;
  text-align: center;
  font-family: Almoni;
  font-weight: 600;
  /* color: #1076ba; */
  color: red;
}

.manual-waybill .vkb-wrapper.vkb-absolute {
  bottom: 110px;
}
.logo-service-icon {
  position: fixed;
  top: 143px;
  right: 63px;
  padding: 9px 7px;
  z-index: 1;
}
.logo-service-icon.dhl {
  background-color: #ffcc01;
}
.retry-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.retry-text {
  margin-top: 4rem;
  font-size: 32px;
  font-weight: 600;
  font-family: AlmoniTzar;
}
