.payment-take-card {
  width: 620px;
  height: 300px;
}

.payment-take-card .slot,
.payment-take-card .card,
.payment-take-card .arrowOut {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-take-card .slot img {
  width: 200px;
}
.payment-take-card .card {
  position: relative;
  overflow: hidden;
  margin-top: -30px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.payment-take-card .card .image-wrapper img {
  display: block;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 16s;
  animation-name: cardslideout;
  animation-iteration-count: 1;
  animation-delay: 2s;
  margin-top: -50px;
}

@keyframes cardslideout {
  from {
    margin-top: -50px;
  }
  10% {
    margin-top: 50px;
  }
  95% {
    margin-top: 50px;
  }
  to {
    margin-top: -50px;
  }
}
.payment-take-card .arrowOut img {
  width: 80px;
  animation-duration: 3s;
  animation-name: slideout;
  animation-iteration-count: 4;
}

@keyframes slideout {
  from {
    margin-top: 0px;
  }
  50% {
    margin-top: 20px;
  }
  to {
    margin-top: 0;
  }
}
