.cus-ser-wrapper {
  direction: ltr;
  font-family: AlmoniTzar;
  white-space: nowrap;
  color: #0f5687;
  font-size: 23px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
}

.cus-ser-inner-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  justify-content: center;
}

.cus-ser-phone-icon {
  width: 32px;
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;
}
