.map-wrapper {
  width: 482px;
  height: 415px;
  margin: 30px 10px 74px 13px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 2px #fc5726;
  overflow: hidden;
  z-index: 2;
}
.leaflet-container {
  width: 100%;
  height: 100%;
}
.big-map {
  width: 100%;
  height: 100%;
  margin: auto;
}
.padding-0 {
  padding: 0;
}
